.progressbox {
  position: relative;
  width: 170px;
  height: 21px;
  z-index: 11;
}

.autoplayprogress {
  position: relative;
  width: 115px;
  margin: 0 auto;
  height: 10px;
  z-index: 10;
}

.autoplayprogress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 4.5px;
  z-index: 10;
  width: 100%;
  height: fit-content;
  stroke-width: 3px;
  stroke: rgba(255, 255, 255, 0.8);
  fill: none;
  stroke-dashoffset: calc(100 * (1 - var(--progress)));
  stroke-dasharray: 100;
}

.swiperbuttonnext,
.swiperbuttonprev {
  position: relative;
  width: 21px;
  height: 21px;
  top: 0;
  margin-top: 0;
  left: 0;
  right: 0;
  z-index: 11;
}

.swiperbuttonnext:after,
.swiperbuttonprev:after {
  content: none;
}

.swiperbuttonnext {
  background-image: url("/public/images/icon-arrow-right.svg") !important;
}

.swiperbuttonprev {
  background-image: url("/public/images/icon-arrow-left.svg") !important;
}

@media screen and (max-width: 480px) {
  .allbox {
    bottom: 0;
    height: 30px;
  }
}

.progressbox span {
  opacity: 0.7;
}

.bg::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 200px;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 80%,
      #fff 100%
    ),
    linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 100%);
  z-index: 0;
  transition: 0.3s ease;
  bottom: 0;
  z-index: 1;
}

.bg2::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  height: 200px;
  background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 80%,
      #fff 100%
    ),
    linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #fff 100%);
  z-index: 0;
  transition: 0.3s ease;
  top: 0;
  z-index: 1;
}

.img_area {
  height: 400px;
  overflow: hidden;
  transform: translate3d(0, 0px, 0);
  transition-duration: 1s;
  transition-delay: 0.3s;

  animation: imageScale 2s forwards ease-in-out;
}

@keyframes imageScale {
  from {
    transform: scale(1.5);
  }
  to {
    transform: scale(1);
  }
}

@media screen and (max-width: 600px) {
  .img_area {
    height: 200px;
  }
  .bg::before {
    height: 150px;
  }
}

.subCard {
  animation: paddingtop 2s forwards ease-in-out;
}

@keyframes paddingtop {
  from {
    padding-top: 0;
  }
  to {
    padding-top: 70px;
  }
}

.gradient::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 180px;
  background-image: linear-gradient(
      to bottom,
      rgba(250, 250, 252, 0) 80%,
      #fafafc 100%
    ),
    linear-gradient(to bottom, rgba(250, 250, 252, 0) 0%, #fafafc 100%);
  transition: 0.3s ease;
  z-index: 1;
}

@media screen and (max-width: 600px) {
  .gradient::before {
    height: 80px;
  }
}

.borderLine::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--point-color);
  opacity: 0;
  transition: 0.5s;
}

.borderLine:hover:before {
  opacity: 1;
}

.borderLine::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--point-color);
  opacity: 0;
  transition: 0.5s;
}

.borderLine:hover:before {
  opacity: 1;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  height: 500px !important;
}

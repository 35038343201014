@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
}

:root {
    --max-width: 1280px;
    --max-inner-width: 1280px;
    --pad-inner: 20px;

    --background-color: white;
    --main-color: #0000;
    --point-color: #0077ed;
    --light-color: #fafafc;
    --font-color: #1d1d1f;

    --font-size-h1: 4.5rem;
    --font-size-h2: 4rem;
    --font-size-h3: 3.5rem;
    --font-size-h4: 3rem;
    --font-size-h5: 2.5rem;
    --font-size-p: 20px;
    --font-size-sm: 1rem;
}

* {
    line-height: normal;
}

html,
body {
    scroll-behavior: smooth;
    word-break: keep-all;
    max-width: 100vw;
    background-color: var(--background-color);
    color: var(--font-color);

    z-index: 0;
    cursor: default;

    font-family: 'Pretendard-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input,
textarea {
    outline: none;
}

input:focus,
textarea:focus {
    border: 1px solid var(--point-color);
}

.wrap {
    max-width: var(--max-width);
    margin: 0 auto;
    padding-left: var(--pad-inner);
    padding-right: var(--pad-inner);
}

/* font-size 반응형 */

@media screen and (max-width: 600px) {
    :root {
        --pad-inner: 14px;

        --font-size-h1: 48px;
        --font-size-h2: 38px;
        --font-size-h3: 30px;
        --font-size-h4: 24px;
        --font-size-h5: 18px;
        --font-size-p: 16px;
        --font-size-sm: 12px;
    }

    .section-title {
        font-size: 20px;
        letter-spacing: -0.4px;
    }
    .main-title {
        font-size: 11.733vw;
        line-height: 1;
    }

    .faq .card.active {
        height: 160px !important;
    }

    .faq .card {
        height: 47px !important;
    }
}

@media screen and (min-width: 600px) and (max-width: 768px) {
    :root {
        --font-size-h1: 50px;
        --font-size-h2: 44px;
        --font-size-h3: 38px;
        --font-size-h4: 32px;
        --font-size-h5: 26px;
        --font-size-p: 20px;
        --font-size-sm: 14px;
    }

    .main-title {
        font-size: 4.583vw;
    }
}

@media screen and (min-width: 768px) and (max-width: 1580px) {
    :root {
        --font-size-h1: 4rem;
        --font-size-h2: 3.5rem;
        --font-size-h3: 3rem;
        --font-size-h4: 2.5rem;
        --font-size-h5: 2rem;
        --font-size-p: 20px;
        --font-size-sm: 1rem;
    }

    .main-title {
        font-size: 6.875vw;
    }
}

.pp {
    font-family: 'Poppins', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 700;
}

h1 {
    font-size: var(--font-size-h1);
}

h2 {
    font-size: var(--font-size-h2);
}

h3 {
    font-size: var(--font-size-h3);
}

h4 {
    font-size: var(--font-size-h4);
}

h5 {
    font-size: var(--font-size-h5);
}

p {
    font-size: var(--font-size-p);
    font-weight: 500;
}

a {
    font-size: var(--font-size-sm);
}

small {
    font-size: var(--font-size-sm);
}

blockquote {
    font-size: var(--font-size-sm);
}

.main-title {
    font-weight: 700;
    font-size: 88px;
    line-height: 1.2;
}

.section-title {
    font-size: 30px;
}

.hoverLine {
    width: fit-content;
    margin: 0;
}

.hoverLine:after {
    display: block;
    content: '';
    border-bottom: solid 1px rgb(82, 82, 91);
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
}

.hoverLine:hover:after {
    transform: scaleX(1);
}

.swiper-slide-active #img {
    animation: imageScale 6.5s forwards ease;
}

.swiper-slide-active .title1 {
    animation: textAos 2s forwards ease-in-out;
}

.swiper-slide-active .title2 {
    animation: textAos 2s 1s forwards ease-in-out;
}

@keyframes imageScale {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.3);
    }
}

@keyframes textAos {
    0% {
        opacity: 0;
        transform: translate(0, 1.25rem);
    }

    50% {
        transform: translate(0, 0);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}

.onProcess .title h4 {
    animation: textAos 1s forwards ease-in-out;
}

.thumb,
.home_notice {
    animation: textAos 1s forwards ease-in-out;
}

.onProcess .title p {
    animation: textAos 1s 0.2s forwards ease-in-out;
}

.onProcess #card_mo {
    animation: textAos 1s forwards ease-in-out;
}

#card::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: lightGray;
    transform: translate(0, 100%);
    z-index: 1;
}

#card.right::before {
    right: 0;
}

.onProcess #card::before {
    animation: maskFill 1s ease-in-out both, maskWidth 1.5s ease-in-out 0.5s both;
}

.onProcess #imgArea {
    animation: projecShow 1s ease 0.8s both;
    transition: width 0.3s ease 0s, height 0.3s ease 0s;
}

#imgArea::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--light-color);
    opacity: 0;
    transition: opacity 0.5s ease;
}

.onProcess #textArea {
    animation: projecShow 0.8s ease 0.5s both;
    transform: translate(0%, 0%);
    transition: transform 1s ease 0.5s;
}

@keyframes maskFill {
    0% {
        transform: translate(0, 100%);
    }
    100% {
        transform: translate(0, 0%);
    }
}
@keyframes maskWidth {
    0% {
        width: 100%;
    }
    100% {
        width: 0%;
    }
}
@keyframes projecShow {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.onProcess .hover #imgArea {
    width: 100%;
}
.onProcess .hover #textArea {
    display: none;
}

.onProcess .hover #imgText {
    animation: projecShow 1s ease both;
}

.boardContents img {
    max-height: 500px !important;
}

.faq .card.active {
    height: 200px;
}

.faq .card.active .icon {
    transform: rotate(180deg);
}
.faq .card.active .q {
    color: var(--point-color);
}

.faq .card {
    height: 68px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.hoverBtn {
    background-image: linear-gradient(45deg, white 50%, transparent 50%);
    background-position: 100%;
    background-size: 400%;
    transition: all 300ms ease-in-out;
}

.hoverBtn:hover {
    background-position: 0;
    color: var(--point-color);
}

.m-326d024a[data-active] {
    background-color: var(--pagination-active-bg) !important;
}
